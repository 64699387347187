import Alpine from 'alpinejs';
window.Alpine = Alpine;

const app_lang = document.querySelector("meta[name='app_lang']").getAttribute('content');
const app_api_url = document.querySelector("meta[name='app_api_url']").getAttribute('content');

// Form (Enquiry)
Alpine.data('formEnquiry', () => ({
    formData : {
        reason_for_enquiry : '',
    },
    enquiry_types : [],
    display_address_field_reasons : [],
    countries : [],

    init() {

        // Enquiry Types
        if (app_api_url) {
            fetch(app_api_url + '/data/enquiry_types?lang=' + app_lang)
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        data.forEach(item => {
                            this.enquiry_types.push({
                                value: item.type,
                                label: item.type,
                            });

                            if (item.display_address_fields)
                                this.display_address_field_reasons.push(item.type);
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        // All the rest
        fetchData('/data/countries?lang=en', this.countries);
    },
}));

Alpine.store('formColourMatch', {
    step: 1,
})

// Form (Colour Match)
Alpine.data('formColourMatch', () => ({
    formData : {
        product_required : '',
        pellet_size : '',
        pref_let_down_rate : '',
        light_source : '',
        effect : '',
        type_of_sample_req : '',
        moulded_material : '',
        moulding_process : '',
        regulatory_requirements : '',
        inside_or_outside_app_and_life_expectancy : '',
        enquiry_token : '',
    },
    products : [],
    pellet_sizes : [],
    let_down_rates : [],
    light_sources : [],
    effects : [],
    sample_types : [],
    mouldable_materials : [],
    moulding_processes : [],
    reg_reqs : [],
    in_out_app_life_expectancies : [],

    init() {
        fetchData('/data/products?lang=en', this.products);
        fetchData('/data/pellet-sizes?lang=en', this.pellet_sizes);
        fetchData('/data/let-down-rates?lang=en', this.let_down_rates);
        fetchData('/data/light-sources?lang=en', this.light_sources);
        fetchData('/data/effects?lang=en', this.effects);
        fetchData('/data/sample-types?lang=en', this.sample_types);
        fetchData('/data/mouldable-materials?lang=en', this.mouldable_materials);
        fetchData('/data/moulding-processes?lang=en', this.moulding_processes);
        fetchData('/data/reg-reqs?lang=en', this.reg_reqs);
        fetchData('/data/in-out-app-life-expectancies?lang=en', this.in_out_app_life_expectancies);
    },
}));

// Alpine.start();

function fetchData(url, store) {
    if (app_api_url) {
        fetch(app_api_url + url)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    for (const key in data) {
                        store.push({
                            value: key,
                            label: data[key],
                        });
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}
