'use strict';

export default class StickyHeader {
    constructor() {
        let headerH = document.querySelector('header.header').offsetHeight;

        if (window.pageYOffset > headerH)
            document.querySelector('body').classList.add('is-sticky-header');

        if (window.scrollY > headerH)
            document.querySelector('body').classList.add('sticky-down');

        window.addEventListener('scroll', function() {
            if (window.pageYOffset > headerH)
                document.querySelector('body').classList.add('is-sticky-header');
            else
                document.querySelector('body').classList.remove('is-sticky-header');
        });

        window.onscroll = function(e) {
            let enableCheck = true;
            if(typeof(document.querySelector('.js-modal_wrapper.open')) != 'undefined' && document.querySelector('.js-modal_wrapper.open') != null)
                enableCheck = false;

            if (enableCheck) {
                if (this.oldScroll > this.scrollY) {
                    document.querySelector('body').classList.add('sticky-up');
                    document.querySelector('body').classList.remove('sticky-down');
                } else {
                    document.querySelector('body').classList.add('sticky-down');
                    document.querySelector('body').classList.remove('sticky-up');
                }

                this.oldScroll = this.scrollY;
            }
        }
    }
}
