import { scrollToElem, elemSiblings } from '../functions/support';

window.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-product-range-selector')) {

        // Manage active state
        let btnSiblings = elemSiblings(event.target);

        event.target.classList.add('is-active');

        btnSiblings.forEach(sibling => {
            sibling.classList.remove('is-active');
        });

        // Scroll down
        let pbBlock = event.target.closest('.pb-block-product-range');
        scrollToElem(pbBlock.querySelector('.pb-block-product-range-wrapper'), - document.querySelector('header.header').offsetHeight);
    }
});

window.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-product-range-item')) {
        let productRange = event.target.closest('.product-range');

        // Manage active state
        productRange.querySelectorAll('.js-product-range-item').forEach(item => {
            item.classList.remove('is-active');
        });

        event.target.classList.add('is-active');

        // Desc
        let desc = event.target.querySelector('.product-range-item-desc');
        let info = document.getElementById(desc.dataset.info);
        let infoContent = info.querySelector('.product-info-content');
        let mailto = desc.dataset.mailto;

        infoContent.innerHTML = desc.innerHTML;

        if (desc.innerHTML)
            infoContent.classList.add('is-visible');
        else
            infoContent.classList.remove('is-visible');

        if (mailto)
            info.querySelector('.btn-email').href = mailto;

        // Scroll down
        scrollToElem(info, - document.querySelector('header.header').offsetHeight);
    }
});
