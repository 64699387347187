let gMaps = document.querySelectorAll('.gmap');

if (gMaps.length) {
    let google_maps_api_key = document.querySelector('meta[name="google_maps_api_key"]').content;

    if (google_maps_api_key) {
        window.gMapsCallback = () => {
            gMaps.forEach(gMap => {
                let map = new google.maps.Map(gMap, {
                    mapTypeControl : false,
                    fullscreenControl : false,
                    streetViewControl : false,
                    center : new google.maps.LatLng(parseFloat(gMap.dataset.lat), parseFloat(gMap.dataset.lng)),
                    zoom : parseInt(gMap.dataset.zoom),
                });

                let icon = {
                    url : gMap.dataset.icon,
                    scaledSize : new google.maps.Size(32, 32),
                }

                let marker = new google.maps.Marker({
                    position : new google.maps.LatLng(parseFloat(gMap.dataset.lat), parseFloat(gMap.dataset.lng)),
                    map : map,
                    title : gMap.dataset.name,
                    icon : icon,
                });

                gMapWrapperFix();
                window.addEventListener('resize', () => {
                    gMapWrapperFix();
                });

                function gMapWrapperFix() {
                    let gMapWrapper = gMap.parentElement;
                    let bodyW = document.body.clientWidth;
                    let pbContentW = gMap.closest('.pb-block_content').clientWidth;
                    let addW = (bodyW - pbContentW) / 2;

                    gMapWrapper.style.paddingRight = addW + 'px';

                    let pbBreadcrumb = gMap.closest('.pb-block-google-map').previousElementSibling;

                    if (pbBreadcrumb && pbBreadcrumb.classList.contains('pb-block-breadcrumbs')) {
                        let pbBreadcrumbH = pbBreadcrumb.offsetHeight;

                        gMapWrapper.style.transform = 'translateY(-' + pbBreadcrumbH + 'px)';
                        gMapWrapper.style.marginBottom = '-' + pbBreadcrumbH + 'px';
                        gMapWrapper.style.paddingBottom = pbBreadcrumbH + 'px';
                    }
                }
            });
        };

        let gMapsScript = document.createElement('script');

        gMapsScript.setAttribute("type","text/javascript");
        gMapsScript.setAttribute("src", "https://maps.google.com/maps/api/js?key=" + google_maps_api_key + "&callback=gMapsCallback");

        document.querySelector('body').appendChild(gMapsScript);
    }
}
