/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';
import Cookies from 'js-cookie';


/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload')
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


/*
 * Accordion
 */

import Accordion from './functions/accordion';
const accordion = new Accordion();


/*
 * AOS Animations
 */
import AOS from 'aos';

setTimeout(() => {
    AOS.init({
        duration : 900,
        easing : 'ease-in-out',
        offset : 120,
        debounceDelay: 50,
        throttleDelay: 99,
        once : true,
    });
}, 600);


/*
 * AppendAround
 */

import appendAround from './functions/append-around';

window.addEventListener('load', function() {
    appendAround();
});


/*
 * Copy Share Button
 */

let cookieNotice = document.getElementById('cookie-notice');
if(cookieNotice != 'undefined' && cookieNotice != null && Cookies.get('cookie-notice') !== 'true') {
    document.querySelectorAll('.js-cookie_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('cookie-notice', 'true', { expires: 7 });
            cookieNotice.classList.add('hide');
        }, false);
    });
}


/*
 * Copy Share Button
 */

if(typeof(document.getElementById('share-button')) != 'undefined' && document.getElementById('share-button') != null) {
    document.getElementById('share-button').addEventListener('click', button => {
        var copyText = document.getElementById('share-input');

        copyText.select();
        copyText.setSelectionRange(0, 99999);

        document.execCommand('copy');

        createAlertMsg('valid', copyText.dataset.msg);
    });
}


/*
 * FlashMessage
 */

import { default as FlashMessage, createAlertMsg } from './functions/flash-message';
new FlashMessage();


/*
 * Forms
 */

// Form Fields
import './page-builder/forms';

// Form Validation
import './functions/form-validation';
document.querySelectorAll('.js-validate').forEach(form => {
    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document.querySelector("meta[name='google_recaptcha_site_key']").getAttribute('content');
    } else {
        recaptcha = false;
    }

    new Validate(form, {
        recaptchaSiteKey: recaptcha,
        fieldFeedbackSelector: '.js-field-feedback span',
        ajax: true,
        ajaxSuccessCallback : (form, responseJSON) => {
            let formName = form.querySelector("input[name=\"form\"]").value;
            if (formName === 'colour_match_1') {
                document
                    .querySelectorAll("input[name=\"enquiry_token\"]")
                    .forEach((enquiryTokenInput) => {
                        // https://alpinejs.dev/directives/model#programmatic%20access
                        enquiryTokenInput._x_model.set(responseJSON.enquiry_token);
                    });

                Alpine.store('formColourMatch').step = 2;
                scrollToElem(document.querySelector('.pb-block-colour-match'));
            }

            if (formName === 'colour_match_2') {
                Alpine.store('formColourMatch').step = 3;
                scrollToElem(document.querySelector('.pb-block-colour-match'));
            }
        },
        ajaxErrorCallback : (form) => {
            // ...
        },
    });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
    field.addEventListener('keyup', event => {
        field.setAttribute('value', field.value);
    });
});

// Handle Forms
// import handleForm from './functions/handle-forms';

// const reCaptchaSiteKey = document.querySelector('meta[name="google_recaptcha_site_key"]').getAttribute('content');
// const forms = document.querySelectorAll('form.js-validate');

// if (reCaptchaSiteKey && forms) {
//     forms.forEach((form, index) => {
//         handleForm(
//             form,
//             {
//                 id: index,
//                 reCaptchaSiteKey: reCaptchaSiteKey,
//             },
//             (form, data, message) => {
//                 const formName = form.querySelector(`input[name="${form}"]`).value;

//                 if (formName === 'colour_match_1') {
//                     document
//                         .querySelectorAll(`input[name="${enquiry_token}"]`)
//                         .forEach((enquiryTokenInput) => {
//                             enquiryTokenInput._x_model.set(data.enquiry_token);
//                         });

//                     Alpine.store('formColourMatch').step = 2;
//                     scrollToElem(document.querySelector('.pb-block-colour-match'));
//                 }

//                 if (formName === 'colour_match_2') {
//                     Alpine.store('formColourMatch').step = 3;
//                     scrollToElem(document.querySelector('.pb-block-colour-match'));
//                 }

//                 createAlertMsg('valid', message);
//             },
//             (form, data, message) => {
//                 createAlertMsg('invalid', message);
//             }
//         );
//     });
// }

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('js-scroll_colour-match-form')) {
        scrollToElem(document.querySelector('.pb-block-colour-match'));
    }
});


/*
 * Google Map
 */

import './page-builder/google-map';


/*
 * GoTo Elem
 */

if(typeof(document.querySelector('.js-goto_elem')) != 'undefined' && document.querySelector('.js-goto_elem') != null) {
    document.querySelectorAll('.js-goto_elem').forEach(el => {
        let elem = document.getElementById(el.dataset.elem);

        if(elem) {
            el.addEventListener('click', event => {
                scrollToElem(elem);
            });
        }
    });
}

// On Page Load
window.addEventListener('load', () => {
    if(window.location.hash) {
        let elem = document.getElementById(window.location.hash.substring(1));

        if (elem)
            scrollToElem(elem, 24);
    }
}, false);


/*
 * Grid Accordion
 */

import './page-builder/grid-accordion';

// Heading height fix
window.addEventListener('load', () => {
    const gridAccordions = Array.from(document.querySelectorAll('.accordion-grid'));

    if (gridAccordions.length) {
        gridAccordions.forEach(gridAccordion => {
            const gridAccordionItemsH = Array.from(gridAccordion.querySelectorAll('.accordion-grid-item-heading span'));

            if (gridAccordionItemsH.length) {
                setGridAccordionItemsH(gridAccordionItemsH);

                window.addEventListener('resize', () => {
                    setGridAccordionItemsH(gridAccordionItemsH);
                });
            }
        });
    }
}, false);

function setGridAccordionItemsH(gridAccordionItemsH) {
    let hHeading = 0;

    gridAccordionItemsH.forEach(heading => {
        heading.style.height = '';

        if (heading.offsetHeight > hHeading) {
            hHeading = heading.offsetHeight;
        }
    });

    if (hHeading > 0) {
        gridAccordionItemsH.forEach(heading => {
            heading.style.height = hHeading + 'px';
        });
    }
}


/*
 * Header Language
 */

let header_lang = document.getElementById('header-lang');
if(header_lang != 'undefined' && header_lang != null && Cookies.get('header_lang') !== 'true') {
    document.querySelectorAll('.js-header-lang-close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('header_lang', 'true', { expires: 7 });
            document.querySelector('body > .wrapper').style = '';
            document.querySelector('.wrapper.has-header-lang').classList.remove('has-header-lang');
            header_lang.classList.add('hide');

            setTimeout(() => {
                document.querySelector('body > .wrapper').style.marginTop = document.querySelector('header.header').offsetHeight + 'px';
            }, 300);
        }, false);
    });
}


/*
 * Icons
 */

if(typeof(document.querySelector('[data-icon]')) != 'undefined' && document.querySelector('[data-icon]') != null) {
    document.querySelectorAll('[data-icon]').forEach(btnIcon => {
        let spanIcon = document.createElement('span');
        spanIcon.classList.add('btn-icon');
        spanIcon.innerHTML = '<svg class="ico"><use xlink:href="#icon-' + btnIcon.dataset.icon + '"></use></svg>';
        btnIcon.prepend(spanIcon);
    });
}


/*
 * Mobile Main Navigation
 */

// let navMobileTrigger = document.querySelector('.js-nav-primary-trigger');
// if(typeof(navMobileTrigger) != 'undefined' && navMobileTrigger != null) {
//     navMobileTrigger.addEventListener('click', () => {
//         console.log(1);
//     });
// }


/*
 * Modal Window
 */

import { default as ModalWindow } from './functions/modal-window';
new ModalWindow();


/*
 * Primary Navigation
 */

// Default
let navPrimaryLink = document.querySelector('.nav-primary-link');
if (navPrimaryLink) {
    if (document.querySelector('.nav-primary-link.is-open') == null) {
        navPrimaryLink.classList.add('is-open');
    }
}
let subnavPrimary = document.querySelector('.subnav-primary');
if (subnavPrimary) {
    if (document.querySelector('.subnav-primary.is-open') == null) {
        document.querySelector('.subnav-primary').classList.add('is-open');
    }
}

// Submenus
let headerBottom = document.querySelector('.header-bottom');

if (headerBottom) {
    let submenus = document.querySelectorAll('.subnav-primary');

    if (submenus) {
        window.addEventListener('load', () => {
            setSubmenuHeight(headerBottom, submenus);
        });
        window.addEventListener('resize', () => {
            setSubmenuHeight(headerBottom, submenus);
        });
    }
}

function setSubmenuHeight(headerBottom, submenus) {
    let maxHeight = 0;

    headerBottom.style.height = '';

    submenus.forEach(submenu => {
        submenu.style.display = 'block';
    });

    submenus.forEach(submenu => {
        if (submenu.clientHeight > maxHeight) {
            maxHeight = submenu.clientHeight;
        }
    });

    submenus.forEach(submenu => {
        submenu.style.display = '';
    });

    headerBottom.style.height = maxHeight + 'px';
}

// Trigger
if(typeof(document.querySelector('.nav-primary-link')) != 'undefined' && document.querySelector('.nav-primary-link') != null) {
    document.querySelectorAll('.nav-primary-link:not(.is-open)').forEach(trigger => {

        // Mouseenter menu item
        trigger.addEventListener('mouseenter', () => {
            if (trigger.classList.contains('js-subnav-trigger')) {
                let triggerSiblings = elemSiblings(trigger.closest('.nav-primary-item'));
                let subnav = document.getElementById(trigger.dataset.subnav);
                let subnavSiblings = elemSiblings(subnav);

                triggerSiblings.forEach(sibling => {
                    if (sibling.querySelector('.js-subnav-trigger')) {
                        sibling.querySelector('.js-subnav-trigger').classList.remove('is-mouseenter');
                        sibling.querySelector('.js-subnav-trigger').classList.add('is-inactive');
                    }
                });

                subnavSiblings.forEach(sibling => {
                    sibling.classList.add('is-hidden');
                });

                trigger.classList.add('is-mouseenter');
                subnav.classList.add('is-open');
            } else {
                subnavMouseleave(document.querySelector('.js-subnav-trigger.is-mouseenter'));
            }
        });
    });

    // Mouseleave header
    document.querySelector('.header').addEventListener('mouseleave', () => {
        subnavMouseleave(document.querySelector('.js-subnav-trigger.is-mouseenter'));
    });

    function subnavMouseleave(trigger) {
        if (trigger) {
            let triggerSiblings = elemSiblings(trigger.closest('.nav-primary-item'));
            let subnav = document.getElementById(trigger.dataset.subnav);
            let subnavSiblings = elemSiblings(subnav);

            triggerSiblings.forEach(sibling => {
                if (sibling.querySelector('.js-subnav-trigger')) {
                    sibling.querySelector('.js-subnav-trigger').classList.remove('is-inactive');
                }
            });

            subnavSiblings.forEach(sibling => {
                sibling.classList.remove('is-hidden');
            });

            trigger.classList.remove('is-mouseenter');
            subnav.classList.remove('is-open');
        }
    }
}


/*
 * Product Range
 */

import './page-builder/product-range';


/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
new StickyHeader();

// Dynamic header height
window.addEventListener('load', () => {
    document.querySelector('body > .wrapper').style.marginTop = document.querySelector('header.header').offsetHeight + 'px';
});
window.addEventListener('resize', () => {
    document.querySelector('body > .wrapper').style.marginTop = document.querySelector('header.header').offsetHeight + 'px';
});


/*
 * Swiper
 */

import Swiper from './vendor/swiper';
window.addEventListener('load', () => {
    document.querySelectorAll('.js-swiper').forEach(swiper => {
        let params = JSON.parse(swiper.dataset.swiper);
        let mySwiper = new Swiper(swiper, params);

        // Swiper Height
        document.addEventListener('lazyloaded', () => {
            if(swiper.dataset.autoheight)
                mySwiper.updateAutoHeight();
        });

        // Hero Slider
        if (swiper.classList.contains('hero-slider')) {
            setTimeout(() => {
                swiper.classList.add('add-animation');
            }, 300);
        }

        // TMS Sliders
        if (swiper.classList.contains('tms-slider')) {
            let swiperID = swiper.id;

            swiper.querySelectorAll('.tms-slide:not(.swiper-slide-duplicate) .tms-slide-heading').forEach((heading, index) => {
                let pagination = document.getElementById(swiperID + '_pagination');

                if (pagination) {
                    pagination.children[index].classList.add(heading.dataset.paginationColor);
                    pagination.children[index].insertAdjacentHTML('beforeend', '<span>' + heading.innerHTML + '</span>');
                }
            });
        }
    });
}, false);

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
    goto.addEventListener('click', event => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});


/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);


/*
 * Support Functions
 */

import { scrollToElem, slideUp, slideDown, elemSiblings } from './functions/support';


/*
 * Cookie Controller
 */

/*
import './functions/cookie-controller';
const metaCC = JSON.parse(document.querySelector('meta[name="cc"]').content);

const cookieController = new CookieController({
    layout: 'banner',
    requiredCookies: ['PHPSESSID'],
    websiteName: metaCC.websiteName,
    cookiePolicyLink: metaCC.cookiePolicyLink,
    bannerContent: metaCC.bannerContent,
    optionalGroups: [
    ],
});
*/


/*
 * GA Events
 */

// import './functions/ga-events';

/**
 * Social(s)
 * This logic is dependent on Alpine.js (previously incl. by forms.js) as it makes the templating
 * much easier to manage.
 */
document.addEventListener('alpine:init', () => {
    Alpine.store('gws',{
        li: {
            loading: false,
            posts: {},
        },
    });

    const gwsRestUrlEl = document.querySelector('meta[name="gws_rest_url"]');
    if (!gwsRestUrlEl) {
        return;
    }
    const gwsRestUrl = gwsRestUrlEl.getAttribute('content');

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const gwsLi = document.querySelector('.js-gws-li'); // First element (and therefore prevents dup. AJAX requests)
    if (gwsLi) {
        const gwsLiIo = new IntersectionObserver((entries, observer) => {
            // if (entries[0].intersectionRatio > 0) { // Load regardless of visibility on page.
                Alpine.store('gws',{
                    init() {
                        this.li.loading = true;

                        fetch(`${gwsRestUrl}/li/posts`)
                            .then(response => response.json())
                            .then(data => {
                                this.li.loading = false;
                                this.li.posts = data;

                                // Fix Swiper(s) height(s) (where applicable)
                                document.querySelectorAll('.js-swiper').forEach(el => {
                                    if (typeof el.swiper === 'object') {
                                        // Hacky but seems to be the only way to consistently fix the height...
                                        setTimeout(() => {
                                            el.swiper.update();
                                        }, 500);
                                    }
                                });
                            }).catch((error) => {
                                // console.error('Error:', error);
                            });
                    },
                    li: {
                        loading: false,
                        posts: {},
                    },
                });

                gwsLiIo.unobserve(gwsLi);
            // }
        });
        gwsLiIo.observe(gwsLi);
    }
});

Alpine.start(); // Must be called after event 'alpine:init' listener.
