import { slideUp, slideDown } from '../functions/support';

window.addEventListener('click', (event) => {
    if (event.target.classList.contains('accordion-grid-item-readmore')) {
        const accordionGrid = event.target.closest('.accordion-grid');
        const moreElem = document.getElementById(event.target.dataset.more);

        accordionGrid.querySelectorAll('.accordion-grid-item-readmore').forEach(btn => {
            if (btn !== event.target) {
                btn.classList.remove('is-active');
            } else {
                btn.classList.toggle('is-active');
            }
        });

        accordionGrid.querySelectorAll('.accordion-grid-item-more').forEach(elem => {
            if (elem.id !== moreElem.id) {
                elem.classList.remove('is-active');
                slideUp(elem);
            } else {
                elem.classList.toggle('is-active');

                if (event.target.classList.contains('is-active')) {
                    slideDown(elem);
                } else {
                    slideUp(elem);
                }
            }
        });
    }
});
